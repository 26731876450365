import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import ReactMarkdown from "react-markdown"
import Moment from "react-moment"

import Layout from "../components/layout";
import {getComponent} from "./page";

export const query = graphql`
  query ArticleQuery($id: ID!) {
    strapi {
      blogArticle(id: $id ) {
        id
        slug
        meta_description
        meta_robots
        og_fb_image {
            url
        }
        og_twitter_image {
            url
        }
        title
        Content {
          ... on Strapi_ComponentPageComponentsHero {
              id
              background_image {
                  url
              }
              title
              subtitle
              text
          }
          ... on Strapi_ComponentPageComponentsHeroVideo {
              id
              background_image {
                  url
              }
              title
              subtitle
              text
              video_crop
              youtube_id

          }
          ... on Strapi_ComponentPageComponentsLogos {
              id
              title
              background_color
              invert_color
              extra_padding
              logos {
                id
                alternativeText
                url
                width
                height
              }
          }
          ... on Strapi_ComponentPageComponentsTextVideoRight {
              id
              Title
              background_image {
                url
              }
              content
              embed_video
              video_still {
                id
                alternativeText
                url
              }
              strap_title
              subliminal_text
              extra_link
          }
          ... on Strapi_ComponentPageComponentsBandLineUp {
              id
              include_list
              exclude_list
          }
          ... on Strapi_ComponentPageComponentsImageLeftTextRight {
              id
              Title
              Content
              link
              link_title
              main_img {
                  id
                  alternativeText
                  url
              }
              secondary_img {
                  id
                  alternativeText
                  url
              }
              subliminal_message
              video_embed
              video_thumbnail {
                alternativeText
                url
              }
          }
          ... on Strapi_ComponentPageComponentsImageRightTextLeft {
              id
              Title
              Content
              vertical_title
              primary_img {
                  id
                  alternativeText
                  url
              }
          }

          ... on  Strapi_ComponentPageComponentsCarousel {
              id
              Title
              subtitle
              background_color
              read_more_link
              read_more_text
              background_img {
                  url
              }
              carousel_item {
                  id
                  title
                  text
                  link
                  image {
                      url,
                      alternativeText
                  }
              }
          }
          ... on  Strapi_ComponentPageComponentsBookJamHot {
              id
              Title
              vertical_title
              Content
              book_image {
                  alternativeText
                  url
                  width
                  height
              }
              book_title
              book_subtitle
              book_link_text
              book_link
              full_width_video_embed
              video_still {
                id
                alternativeText
                url
              }
          }
          ... on  Strapi_ComponentPageComponentsVideoCarousel {
              id
              Title
              video_item {
                  id
                  embed_code
                  video_still {
                    id
                    alternativeText
                    url
                  }
              }
          }
          ... on  Strapi_ComponentPageComponentsSonglist {
              id
              include_list
              exclude_list
          }
          ... on  Strapi_ComponentPageComponentsGallery {
              id
              Title
              images {
                  id
                  url
                  width
                  height
                  alternativeText
              }
          }
          ... on  Strapi_ComponentPageComponentsVideoCarousel {
              id
              Title
              video_item {
                  id
                  embed_code
              }
          }
          ... on  Strapi_ComponentPageComponentsReviews {
              id
              Title
              vertical_title
              content
              reviews_link_text
              reviews_link
              background_image {
                  id
                  url
                  width
                  height
                  alternativeText
              }
              reviews {
                id
                content
              }
          }
          ... on  Strapi_ComponentPageComponentsPerformanceMap {
              id
              Title
              Content
              vertical_title
              map_title
              map_img {
                  id
                  url
                  width
                  height
                  alternativeText
              }
              location_list {
                  id
                  location
                  link
                  image {
                      id
                      url
                      width
                      height
                      alternativeText
                  }
              }
          }

          ... on Strapi_ComponentPageComponentsHeroImage {
              id
              image {
                  url
              }
              Title
          }
          ... on Strapi_ComponentPageComponentsFullWidthBook {
            id
            book_button_link
            book_button_text
            book_subtitle
            book_title
            image {
              alternativeText
              height
              url
              width
            }
            social_title
          }
          ... on Strapi_ComponentPageComponentsFullReviews {
            id
            Title
            event_type {
                name
            }
            vertical_title
            background_image {
                id
                url
            }
          }
          ... on Strapi_ComponentPageComponentsGalleryMasonry {
              id
              images {
                  id
                  url
                  alternativeText
                  width
                  height
              }
          }
          ... on Strapi_ComponentPageComponentsGalleryCollage {
              id
              VerticalTitle,
              link,
              link_title
              Images {
                  id
                  url
                  alternativeText
                  width
                  height
              }
          }
          ... on Strapi_ComponentPageComponentsProductionOptions {
            id
            VerticalTitle
            Subtitle
            Title
            BottomSectionPO {
              Column2
              Column3
              Content
              Title
              Image {
                alternativeText
                url
                height
                width
              }
            }
            TopSectionPO {
              Title
              OptionColumnPo {
                Content
                Subtile
                Title
                Image {
                  alternativeText
                  height
                  width
                  url
                }
              }
            }
          }
          ... on Strapi_ComponentPageComponentsVideoThumbText {
            id
            Title
            Content
            VideoEmbed
            VideoThumbnail {
              alternativeText
              height
              url
              width
            }
            BackgroundImage{
              alternativeText
              height
              url
              width
            }
          }
          ... on Strapi_ComponentPageComponentsTwoColumnTitleText {
            id
            Column1Content
            Column1Title
            Column2Content
            Column2Title
            Subtitle
            Title
            VerticalTitle
          }
          ... on Strapi_ComponentPageComponentsTextImageText {
            id
            Column1Content
            Column1Title
            Column2Content
            Column2Title
            Title
            VerticalTitle
            Image {
              alternativeText
              height
              url
              width
            }
          }
          ... on Strapi_ComponentPageComponentsTextBlock {
            id
            Content
            Title
            VerticalTitle
            BackgroundImage {
              alternativeText
              url
              height
              width
            }
            BackgroundColour
            InvertTextColour
          }
          ... on Strapi_ComponentPageComponentsContactBlock {
              id
              ContactFormTitle
              Content
              SocialTitle
              Title
              BackgroundImage {
                alternativeText
                height
                url
                width
              }
          }
          ... on Strapi_ComponentPageComponentsFullPerformanceMap {
            id
            vertical_title
            Title
            Content
            MapLocations {
              Region
              location_list {
                id
                event_type {
                  EventTitle
                  id
                  location_list {
                    id
                    image {
                      alternativeText
                      width
                      url
                      height
                    }
                    link
                    location
                  }
                }
              }
              id
            }
            map_img {
              alternativeText
              url
              width
              height
            }
          }
          ... on Strapi_ComponentPageComponentsVideoCollection {
            id
            Content
            Title
            VideoRow {
              id
              Content
              Title
              VideoEmbed
              VideoThumbnail {
                alternativeText
                url
                width
                height
              }
              VideoTitle
            }
          }
          ... on Strapi_ComponentPageComponentsVideoOffsetBlock {
            id
            VideoBlock {
              id
              Subtitle
              Title
              VerticalTitle
              VideoEmbed
              VideoThumbnail {
                alternativeText
                height
                url
                width
              }
              VideoTitle
            }
          }
        }
      }
    }
  }
`

const Article = ({ data }) => {
  console.log(data);
  const article = data.strapi.blogArticle
  const {blogArticle} = data.strapi;
  const {
      meta_description,
      meta_robots,
      og_fb_image,
      og_twitter_image,
      slug,
      title
  } = blogArticle;
  return (
    <>
      <Helmet
            title={title}
            link={[
                {
                  rel: "stylesheet",
                  href: "https://fonts.googleapis.com/css?family=Teko",
                },
                {
                  rel: "stylesheet",
                  href: "https://fonts.googleapis.com/css?family=Oswald",
                }
            ]}
            meta={[
                {
                name: `description`,
                content: meta_description,
                },
                {
                property: `og:title`,
                content: title,
                },
                {
                    property: 'robots',
                    content: meta_robots
                },
                {
                property: `og:description`,
                content: meta_description,
                },
                {
                property: `og:type`,
                content: `website`,
                },
                {
                property: `og:url`,
                content: `http://jamhot.band/${slug ? slug: ''}`,
                },
                {
                property: `og:image`,
                content: og_fb_image?.url,
                },
                {
                  property: `og:image:width`,
                  content: 1200,
                },
                {
                  property: `og:image:height`,
                  content: 630,
                },
                {
                name: `twitter:card`,
                content: `summary_large_image`,
                },
                {
                name: `twitter:title`,
                content: title,
                },
                {
                name: `twitter:description`,
                content: meta_description,
                },
                {
                property: `twitter:url`,
                content: `http://jamhot.band/${slug}`,
                },
                {
                property: `twitter:image`,
                content: og_twitter_image?.url,
                },
                {
                  property: `twitter:image:width`,
                  content: 1200,
                },
                {
                  property: `twitter:image:height`,
                  content: 630,
                },
            ].concat()}
        ></Helmet>
      <Layout>
        <article>
          {article.Content.map((component, index) => getComponent(component, index))}
        </article>
      </Layout>
    </>
  )
}

export default Article
